.mid {
    background-image: url("../assets/LEARN-MORE---HOW-DOES-IT-WORKAdobeStock_328915424.png");
    background-size: cover;
    background-position: center;
    min-height: 70vh;
    margin-top: 3em;
    position: relative;
   
    
    .why {
        color: white;
        font-size: 45px;
        font-weight: 300;
        line-height: 1em;
        margin-top: 2em;
        margin-bottom: 2em;
        text-align: center;

            strong{
                color: white;
            }
    }
                
    .textinside{
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 15px;
        margin-top:10em;
        text-align: left;
        @media only screen and (max-device-width: 480px) {
            text-align: center;
          }
        line-height: 2em;
        margin:1.5em;
        
            strong{
                color: white;
            }
    } 
        
    .learnmore{ 
        z-index:999999;
        position: absolute;
        margin-top:-2em;           
    }
    
    .learnmore:hover{
        opacity: 0.6;
    }
 }

@media(max-width:768px){
    .mid .textinside{
        position: absolute;
        z-index:100;
        text-align: center;
    }

    .mid::before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6)
    }

    .mid .learnmore{
        position: relative;
        z-index:999;
        margin-top: 23em;
        text-align: center; 
    }

    .mid .why {
        position: relative;
        z-index:100;
        color: white;
        font-size: 45px;
        font-weight: 300;
        line-height: 1em;
        margin-top: 0.5em;

            strong{
                color: white;
            }
    }
}