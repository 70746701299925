.whymed {
    background-image: url("../assets/LEARN-MORE---HOW-DOES-IT-WORKAdobeStock_328915424.png");;
    background-size: cover;
    min-height: 70vh;
       
    .level-item {
        align-items: center;
        display: flex;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: left;
        margin-left:1em;
    }
   
    .whytitle{
        color: white;
        padding-top: 2em;
        padding-bottom: 1em;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 45px;
        text-align: center;
        margin-top:0em;

        strong{
            color: white;
        }

        .subtitle{
            color: white;
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 2em;
            margin:1.5em;
            text-align: left; 
        }
    }    
}

.statement {

    .title {
    background-size: cover;
    text-align: center;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;;
    margin-bottom: -0.5em;
    }
    
    .font {
        font-size: 1em;
        line-height: 2em;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin:1.5em;
        text-align: left;  
    }
    
    .tuning{
        text-align: left;  
        font-size: 36px;
        font-weight:300;
        margin-right:1.5em;
        margin-left: 0.7em;
        
    }

    .tuningmessage{
        text-align: left; 
        font-size: 18px;
        font-weight:300;
        line-height: 2em;
        margin-right:1.5em;
        margin-left: 1.5em;
        margin-bottom: 3em;
    }

    .image{
        margin:2em;
    }
}

@media(max-width:768px){
    .statement .font {
        text-align: center;
    }

    .statement .tuning{
        text-align:center;
    }

    .statement .tuningmessage{
        text-align: center;
    }
} 