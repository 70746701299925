.about {
    background-image: url("../assets/ABOUT-US_benjamin-davies-__U6tHlaapI-unsplash.png");;
    background-size: cover;
    min-height: 150vh;
    margin-bottom: 1.5em;
    background-position: center;
      
    .level-item {
        align-items: center;
        display: flex;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: left;
        margin-left:1em;
    }
    
    .title{
        color: white;
        padding-top: 5em;
        padding-bottom: 1em;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 45px;
        text-align: center;
        margin-top:-3em;            
    }
    
    .subtitle{
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 15px;
        line-height: 2em;
        margin:1.5em;
        text-align: left;
                
        strong{
            color: white;       
        }
    }
    
    a{
        color: #0D5E8B;
    }
    a:hover{
        color: white
    }
    
    a{
        color: #0D5E8B;
    }
    a:hover{
        color: white
    }
}

@media(max-width:768px){
    .about .subtitle {
        text-align: center;
    }
} 