.steps{
    background-image: url("../assets/HOW-TO-MEDITATE.png");
    background-size: cover;
    min-height: 70vh;
    text-align: left;
    margin-top: 2em;
    text-align: center;
    margin-bottom: -3em;
    margin-top:1em;

    .how{
        color: white;
        font-size: 45px;
        font-weight: 300;
        line-height: 1em;
        text-align: center;
        margin-top:2em;
    
        strong{
            color: white;
        }          
    }
}
    
.messagecontent{

    .stepstitle{
        margin-top:-1em;
        text-align: center;
        margin-bottom: 1.5em;
    }
  
    .stepscontent{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 2em;
        text-align: left;
        @media only screen and (max-device-width: 480px) {
            text-align: center;
          }
        margin: 2em;

          h1{
              text-align: center;

          }
     }
}

@media(max-width:768px){
    .messagecontent .stepscontent{
    text-align: center;
    }

    .steps .how{
        margin-left: 0.2em;
        margin-right:0.2em;
    }
} 
            



    


  
