.carousel {
    background-image: url("../assets/AdobeStock_226347976-1080_WEB.png") ; 
    background-size: cover;
    min-height: 60vh; 
    margin-bottom: 2em; 
    background-position: center;

    .level-item {
        align-items: center;
        display: flex;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: left;
        margin-left:1em;
    }
    
     .lets {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 45px;
        font-weight: 300;
        line-height: 1.125;
        margin-left:5em;
        margin-top:3em;
        text-align: center;

        strong{
            color: white; 
        }
    }
}
   
@media(max-width:768px){
   .carousel .level-item{
        width: 25%;
        margin-top:-0.5em; 
    }

    .carousel .lets{
        margin-top:-1em;
        text-align: center;
        margin-left:7em;
    }
    
} 


@media(max-width:480px){ 
    .carousel .level-item{
        width: 25%;
        margin-top:-3.2em; 
    }

    .carousel .lets{
        margin-top:1.2em;
        font-size: 35px;
        text-align: center;
        margin-left:0.5em;
    }



}
    
    
    
