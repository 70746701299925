

.vidpage{
    margin-bottom: 10em;


    .titleguided {
    background-size: cover;
    text-align: center;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;;
    margin-bottom: 0.1em;
    margin-left:1em;
    margin-right: 1em;
    }

}

.videotopspacing{
margin-top:8em;
margin-left:0.2em;
margin-right:0.2em
}
