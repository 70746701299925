.live {
    height: 25vh;
    background-image: linear-gradient(#FBBA00,white);
    margin-top: -2em;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    
    .lastcurrent{
        margin-top:1em;
        text-align:center;
        margin-left: 0.8em; 
    }

    .next{
        margin-top:-0.9em;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 800;
    }

    .show{     
       font-size: 1em;
       margin-top: -1.5em;
       font-weight: 600; 
       margin-left: 1.5em; 
       background-color: #0D5E8B; 
       color: white;
    }
}

.number {
    background-color: transparent;
    font-size: 1rem;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-left: 2.5em;
}

.notification {
    background-color: transparent;  
    position: relative;
}

  
.todaytime{
    font-size: 17px;
}

@media(max-width:768px){
    .live .lastcurrent{
        margin-top:0.5em;
    }
}