.statement {
    .titlewhat {
        background-size: cover;
        text-align: center;
        font-size: 45px;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;;
        margin: 1em;
        margin-top: 1.2em; 
        margin-bottom: 0em;
    }
    
    .insidemessage{
   
        text-align: left;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        line-height: 2em;
        font-weight: 400;;
        margin: 1.5em;
    }
}

@media(max-width:768px){
    .statement .titlewhat {
        margin-top: 2.6em;; 
    }

    .statement .insidemessage{
        text-align: center;
    }
} 



