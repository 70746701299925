.logoed {
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: left;
    margin-top: -1em;
}

.humans {
 margin-bottom: 1em;

    .pagetitle {
    background-size: cover;
    text-align: center;
    font-size: 64px;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
     }
 }

.edname{
    text-align: center;

    a {
        color: black;
        cursor: pointer;
        text-decoration: none;  
        font-size: 24px;
    }
    
        a:hover{
            color: #0D5E8B;
    }      
}

.edmessage {
    z-index: 1;
    text-align: center;
    font-style: italic;      
}
