@charset "utf-8";
@import "~bulma/sass/utilities/functions";
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import "~bulma-divider";
// Set your brand colors


// Update Bulma's global variables
$family-sans-serif: "Poppins", sans-serif;

// Update some of Bulma's component variables

body {
    margin: 0;
}