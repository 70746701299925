.homebutton{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:18px;
    text-align:center;
}

.wholink{
    color:#0D5E8B;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:18px;
    text-align:center;
}
