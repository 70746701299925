.contact {
    background-image: url("../assets/CONTACT_US-jeremy-thomas-pq2DJBntZW0-unsplash.png");
    background-size: cover;
    min-height: 100vh;
    margin-bottom: 2em;
    background-position: center;
    
  
    .level-item {
        align-items: center;
        display: flex;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: left;
        margin-left: 1em;
    }

    .subtitle{
        color:white;
        padding-top: 2em;
        padding-bottom: 1em;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 64px;
        text-align: center;
        margin-top:0em;
       
        strong{
            color: white;
            font-weight: 400;   
        }
    }  
}

.infomessage{
    margin-bottom: 2em;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 14px;
    
}

.inputmessage{
    margin-top: 2em;
    color: black;
    font-weight: 500;
}

.inputfield{
    margin:1.5em;
}

@media(max-width:768px){
    .subtitle {
        margin-left: 0.2em;
        margin-right:0.2em;
}
    .infomessage{
        margin-left: 1em;
        margin-right:1em;

    }

   
} 
